import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    gridMargin: {
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(2)
    }
}));

const Mobile = ({ data, onEdit }) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };


    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                title={`Batch Name : ${data.batchName}`}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                    <Grid item class={classes.gridMargin}>
                        <Typography variant="h5" component="h2">
                            {data.shedName}
                        </Typography>
                        <Typography color="textSecondary">
                            Shed Name
                    </Typography>
                    </Grid>
                    <Grid class={classes.gridMargin} item>
                        <Typography variant="h5" component="h2">
                        {data.startDate.Valid ?
                            moment(data.startDate.Time).format("DD-MM-YYYY")
                        :
                        ""
                        }   
                        </Typography>
                        <Typography color="textSecondary">
                            Start Date
                    </Typography>
                    </Grid>
                    <Grid class={classes.gridMargin} item>
                        <Typography variant="h5" component="h2">
                            {data.endDate.Valid ?
                                moment(data.endDate.Time).format("DD-MM-YYYY")
                            :
                            ""
                            }
                        </Typography>
                        <Typography color="textSecondary">
                            End Date
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Menu
            id="shed-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;