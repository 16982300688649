import React, { useState,useRef, useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import ReportInput from "./components/SingleBatchReportInput";
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';
import { changeToLocalString } from '../../common/functions';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { version } from "../../common/enums";
import TableWrapper from "../../components/Table/TableWrapper";
import { generateReportTitle } from "./utils/functions";

window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
  root: props => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
  }),
  topBar: {
    marginBottom: theme.spacing(2)
  },
  link: {
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  formControl: {
    width: "100%"
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  progressBar: {
    margin: theme.spacing(2)
  },
  singleLineBorder: {
    fontWeight: "bold",
    borderTop: "solid 1px #F26522",
    borderBottom: "solid 1px #F26522"
  },
  doubleLineBorder: {
    borderTop: "double #F26522",
    borderBottom: "double #F26522"
  },
  reportContainer: {},
  reportHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  rawBatchName: {
    textAlign: "center",
    textDecoration: "underline"
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px"
  },
  body: {
    fontSize: "1em",
    backgroundColor: theme.palette.common.white,
    textAlign:"center",
    padding:"6px", 
    overflow: "hidden",  
    width:"90px" 
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    },
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  }
}))(TableRow);

// const tableHeader = JSON.parse(
//   `[["Date","Bird opening","Mortality", "Bird closing", "Egg opening", " Egg production", "Egg sales", " Egg closing", " Broken Eggs", "Damaged Eggs", "Feed Opening",
//     "Feed Received", " Feed Intake", "Feed Closing"]]`
// );

const generateExcel = function(header, csvdatarow, filterColumn, filename="report") {
  let data = csvdatarow
  let csvData = "";
  header.forEach((column, index) => {
    if (index === 0) csvData = csvData + column;
    else csvData = csvData + "," + column;
  });
  csvData = csvData + "\r\n";

  data.forEach(row => {

    row.forEach((column, index) => {
       //dynamic data by in&out switch
      if (!filterColumn.inValue && !filterColumn.outValue && (index === 21 || index === 20)) {
        return
       } else if (!filterColumn.inValue && index === 20) {
         return
       } else if (!filterColumn.outValue && index === 21) {
         return
       }
      if (index === 0) csvData = csvData + column;
      else csvData = csvData + "," + column;
    });
    csvData = csvData + "\r\n";
  });

  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csvData)
  );
  element.setAttribute("download", `${filename}.csv`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};


const SingleBatchStock = ({ showError, match }) => {
  const theme = useTheme();
  const [{user}] = useGlobalState();
  const [batch, setBatch] = useState("");
  const [shedType, setShedType] = useState("");

  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [data, setData] = useState([]);

  const [column, setColumn] = useState({
    inValue: (user.versionID !== version.lite),
    outValue: (user.versionID !== version.lite)
  })
  const [reportFileName, setReportFileName] = useState("");
  
  
  const tableHeader = useRef( JSON.parse(
    `[["Date","Opening Birds", "Mortality", "Bird Closing", "Egg Opening", "Egg Production", "Egg Sales", "Egg Closing","Broken Egg Opening","Egg Cage Broken","Egg Truck Broken", "Broken Egg sales", "Broken Egg Closing",
    "Daily cage Damage","Truck Damage","Feed Opening", "Feed Received", "Feed Intake", "Feed Closing", "In Value", "Out Value"]]`
  ))

  useEffect(() => {
    const baseHeader = [
      "Date", "Opening Birds", "Mortality", "Bird Closing", "Egg Opening", "Egg Production",
      "Egg Sales", "Egg Closing", "Broken Egg Opening", "Egg Cage Broken", "Egg Truck Broken",
      "Broken Egg sales", "Broken Egg Closing", "Daily cage Damage", "Truck Damage",
      "Feed Opening", "Feed Received", "Feed Intake", "Feed Closing"
    ];
  
    const dynamicColumns = [];
  
    if (column.inValue) {
      dynamicColumns.push("In Value");
    }
  
    if (column.outValue) {
      dynamicColumns.push("Out Value");
    }
  
    const updatedHeader = [...baseHeader, ...dynamicColumns];
  
    tableHeader.current = [updatedHeader];
  }, [column]);
  

  const parseReportFromServer = function(data) {
    const { reportsArray } = data;
    setData(reportsArray);
    console.log(data);
  };

  const getChosenBatch = function(chosenValue) {
    setBatch(chosenValue);
  };

  const getChosenShedType = function(chosenValue) {
    setShedType(chosenValue);
  };

  const generateReport = function() {
    showError("");
    setLoading(true);
    const payload = {
      //batchID: batches[batchIndex].id
      batchID: batch.id,
      shedTypeID: shedType.id
    };

    const response = api.post("reports/single-batch-stock", payload);

    response
      .then(res => {
        parseReportFromServer(res);

        setReportFileName(generateReportTitle({batch: batch, shedType: shedType, reportName: "SingleBatchStock"}));
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError(err.message);
        } else {
          showError("Unknown Error");
        }
        setData([])
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });
  const classes = useStyles({ isDesktop });
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

  const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: reportFileName,
        pageStyle: `
          @media print {
            @page {
              size: landscape !important;
              margin: 0;
            }
          }`,
      });


  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" className={classes.topBar}>
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            Single Batch Stock Report
          </Typography>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3} justify="flex-start" alignItems="center">
          <Grid item  xs={isDesktop ? 5 :12}>
            <ReportInput
              showError={showError}
              match={match}
              setValid={setValid}
              getChosenBatch={getChosenBatch}
              getChosenShedType={getChosenShedType}
              setLoading={setLoading}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={!valid}
              variant="contained"
              color="primary"
              onClick={generateReport}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={generatePDF}
              endIcon={<PictureAsPdfIcon />}
            >
              Download PDF
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={() => generateExcel(tableHeader.current, data,column, reportFileName)}
              endIcon={<GetAppIcon />}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <br />
      {
        user.versionID !== version.lite &&
        <div>
        <FormControlLabel
          control={
            <Switch
              checked={column.inValue}
              onChange={() => setColumn({
                ...column,
                inValue: !column.inValue
              })}
              color="primary"
            />
          }
          label="In Value"
        />
        <FormControlLabel
          control={
            <Switch
              checked={column.outValue}
              onChange={() => setColumn({
                ...column,
                outValue: !column.outValue
              })}
              color="primary"
            />
          }
          label="Out Value"
        />
      </div>
      }
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progressBar} />
        </div>
      ) : (
        <div id="report-container" className={classes.reportContainer} ref={componentRef}>
          <div className={classes.reportHeader}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            >
              {batch.id && batch.name !== ""
                ? `Stock Statment ${batch.name}` +
                  (shedType !== "" ? ` at ${shedType.shed_type}` : "")
                : ""}
            </Typography>
          </div>
          <TableWrapper>
          <Table  stickyHeader aria-label="sticky table">
            <TableHead>
            <StyledTableRow
             align="center" >
                <StyledTableCell colSpan={1}></StyledTableCell>

                <StyledTableCell  align="center" colSpan={3}>Birds</StyledTableCell>
                <StyledTableCell  align="center" colSpan={5}>Good Eggs</StyledTableCell>
                <StyledTableCell align="center" colSpan={5}>Broken Eggs</StyledTableCell>
                <StyledTableCell align="center" colSpan={2}>Damaged</StyledTableCell>
                <StyledTableCell align="center" colSpan={4}>Feed</StyledTableCell>
                {(column.inValue ||column.outValue) && <StyledTableCell align="center" colSpan={2}>Cost</StyledTableCell>}


              </StyledTableRow>
              <StyledTableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Opening</StyledTableCell>
              <StyledTableCell>Mortality</StyledTableCell>
              <StyledTableCell>Closing</StyledTableCell>
              <StyledTableCell>Opening</StyledTableCell>
              <StyledTableCell>Production</StyledTableCell>
              <StyledTableCell>Sales</StyledTableCell>
              <StyledTableCell>Storage</StyledTableCell>
              <StyledTableCell>Closing</StyledTableCell>
              <StyledTableCell>Opening</StyledTableCell>
              <StyledTableCell>Cage</StyledTableCell>
              <StyledTableCell>Truck</StyledTableCell>
              <StyledTableCell>Sales</StyledTableCell>
              <StyledTableCell>Closing</StyledTableCell>
              <StyledTableCell>Daily</StyledTableCell>
              <StyledTableCell>Truck</StyledTableCell>
                <StyledTableCell>Opening</StyledTableCell>
                <StyledTableCell>Received</StyledTableCell>
                <StyledTableCell>In Take</StyledTableCell>
                <StyledTableCell>Closing</StyledTableCell>
                {column.inValue && <StyledTableCell>In Value</StyledTableCell>}
                {column.outValue && <StyledTableCell>Out Value</StyledTableCell>}
              </StyledTableRow>
            </TableHead>
             <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index} colspan={2}>
                {row.map((cols, col) => {
                  if ((col === 20 && !column.inValue) || (col === 21 && !column.outValue)) {
                      return null
                    }
                  return <StyledTableCell align = "left"  key = {col} 
                  className={
                      row[0] === "" ? classes.singleLineBorder : undefined
                    } >
                    {
                        col === 20 || col === 21 || col === 18 ?
                        (
                        (!isNaN(cols) && cols.toString().indexOf('.') !== -1)?
                            parseFloat(cols).toFixed(2)
                            :
                            cols.toLocaleString(user.locales,amountObj))
                            :
                            (
                            (!isNaN(cols) && cols.toString().indexOf('.') !== -1)?
                            changeToLocalString(cols, user.locales)
                            :
                            cols.toLocaleString(user.locales))
                    }
                  </StyledTableCell>
                })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableWrapper>
        </div>
      )}
    </div>
  );
};

export default SingleBatchStock;
